.questions {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 30px;

  @media only screen and (max-width: 600px) {
    padding: 0 0;
  }
}

.questions__subheading {
  width: 70%;
  margin: 0 auto;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
}

.questions__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 40px 0;
  padding: 30px;
  background-color: #f9f9f0;

  @media only screen and (max-width: 600px) {
    padding: 30px 15px;
  }
}

.questions__cta {
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 20px 30px;
  display: flex;
  z-index: 10;
  align-items: center;
  background-color: #e45f4b;
  border-bottom: none;
  box-shadow: none;
}

.questions__cta.fixed {
  display: none;
}

.ant-btn-primary:disabled,
.ant-btn-primary.ant-btn-disabled {
  background-color: #f0f0f0;
  border: none;
}

.questions__stepper {
  margin: 30px auto;
}

.questions__step {
  font-size: 18px;
  font-weight: bold;
}