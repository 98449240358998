.aboutme {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 600px;

  @media only screen and (max-width: 600px) {
    padding: 0 0;
  }
}

.aboutme__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0;
  padding: 30px;

  @media only screen and (max-width: 600px) {
    padding: 30px 15px;
  }
}

.aboutme__image {
  width: 50%;
  max-width: 200px;
}

.aboutme p {
  font-size: 18px;
  line-height: 26px;
}