.goals {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 30px;

  @media only screen and (max-width: 600px) {
    padding: 0 0;
  }
}

.goals__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 40px 0;
  padding: 30px;
  background-color: #f9f9f0;

  @media only screen and (max-width: 600px) {
    padding: 30px 15px;
  }
}

.ant-collapse {
  background-color: white;
  font-size: 16px;
  text-align: left;
  min-width: 70%;
  max-width: 70%;

  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
}