.App {
  text-align: center;
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  text-align: center;
}

h1,
.headline {
  font-size: 72px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 30px;
}

h2 {
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 20px;
}

.main-paragraph {
  font-size: 22px;
  line-height: 30px;
  color: #060606;
  width: 65%;
  margin: 0 auto;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
}

.mini-headline {
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  background-color: #ffda79;
  padding: 10px 20px;
  border-radius: 30px;
  display: inline-block;
  margin-bottom: 20px;
}

.footer {
  margin: 30px auto;
}

.footer__link {
  color: #060606;
}

.footer__download {
  width: 20%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 600px) {
    width: 70%;
    flex-direction: column;
  }
}

.footer__gratitude {
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.footer__download a {
  width: 45%;

  @media only screen and (max-width: 600px) {
    width: 80%;
    display: flex;
    flex-direction: column;
  }
}

.footer__download a img {
  width: 100%;
}
