.question {
  width: 100%;
  text-align: left;
}

.question__title {
  padding: 5px 0;
  margin-bottom: 30px;
  border-radius: 5px;
  font-size: 18px;
}

.question__tags {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;

  @media only screen and (max-width: 600px) {
    padding: 0 0;
  }
}

.ant-tag-checkable {
  background-color: rgba(0,0,0,.05);
  border: none;
  padding: 10px 30px;
  margin: 0 10px 10px;
  white-space: break-spaces;
  border-radius: 50px;

  @media only screen and (max-width: 600px) {
    margin: 0 0 10px;
  }
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  background-color: #ffeab4;
}

.ant-tag-checkable-checked:hover {
  background-color: #ffda79;
}

.ant-tag-checkable-checked {
  background-color: #ffeab4;
  border: none;
  padding: 10px 30px;
  margin: 0 10px 10px;
  white-space: break-spaces;
  border-radius: 50px;
  color: black;

  @media only screen and (max-width: 600px) {
    margin: 0 0 10px;
  }
}