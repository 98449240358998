.modalcontent h2 {
  font-size: 24px;
}

.modalcontent p,
.modalcontent ul li {
  font-size: 18px;
}

.modalcontent li {
  padding-bottom: 10px;
}

.modelcontent__input,
.ant-btn.ant-btn-lg {
  font-size: 14px;
}

p.modelcontent__error {
  font-size: 14px;
  color: red;
}

p.modelcontent__success {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #81c372;
  margin: 0 auto;
}

.ant-input:focus, :where(.css-dev-only-do-not-override-9j5e58).ant-input:focus-within,
.ant-btn-primary {
  box-shadow: none;
}

.ant-btn-default {
  display: none;
}

.ant-modal .ant-modal-footer >.ant-btn+.ant-btn {
  margin: 30px auto;
  padding: 20px 30px;
  display: flex;
  z-index: 10;
  align-items: center;
  background-color: #e45f4b;
  border-bottom: none;
  box-shadow: none;
}

.modalcontent__fetching {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
}
